<template>
    <v-card flat rounded="lg" height="100%" color="transparent">
        <v-img :src="require('@/assets/404.png')" />
    </v-card>
</template>

<script>
export default {
    name: "NotFoundView",
    components: {},
    setup() {
        return {};
    },
};
</script>
